.metadata .ant-collapse-content {
  width: 100%;
  max-height: 512px;
  overflow-y: auto;
}

.metadata .ant-collapse-content p {
  width: 512px;
  max-width: 100%;
}
